.card-module__zDd2sG__card {
  background-color: var(--gray-2);
  border: 1px solid var(--border-subtle);
  flex-direction: column;
  min-height: 440px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card-module__zDd2sG__cardHeader {
  background-color: var(--accent-9);
  color: var(--gray-1);
  flex: 0 0 80px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.card-module__zDd2sG__cardTitle {
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  padding: 0 24px;
}

.card-module__zDd2sG__cardContent {
  flex-direction: column;
  flex: auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 24px 0;
  display: flex;
}

.card-module__zDd2sG__cardFooter {
  justify-content: center;
  margin-top: 15px;
  padding-bottom: 30px;
  display: flex;
}

.ranking-tooltip-module__7oaF9a__wrapper {
  background-color: var(--gray-2);
  box-shadow: var(--shadow-4);
  border-radius: var(--radius-1);
  padding: 20px 10px;
}

.ranking-tooltip-module__7oaF9a__row {
  display: flex;
}

.ranking-tooltip-module__7oaF9a__row + .ranking-tooltip-module__7oaF9a__row {
  margin-top: 25px;
}

.ranking-tooltip-module__7oaF9a__cell {
  flex: 0 0 50%;
  padding: 0 20px;
}

.ranking-tooltip-module__7oaF9a__cell + .ranking-tooltip-module__7oaF9a__cell {
  border-left: 1px solid var(--border-subtle);
}

.ranking-tooltip-module__7oaF9a__title {
  color: var(--gray-12);
  font-size: var(--font-size-1);
  opacity: .5;
  white-space: nowrap;
}

.ranking-tooltip-module__7oaF9a__value {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}

.collapsable-section-module__WXtqqW__wrapper:not(:first-child) {
  margin-top: var(--space-2);
  position: relative;
}

.collapsable-section-module__WXtqqW__header {
  background-color: var(--ui-background);
  color: var(--gray-12);
  cursor: pointer;
  align-items: center;
  gap: var(--space-2);
  height: 60px;
  padding: 0 var(--space-4);
  width: 100%;
  display: flex;
}

.collapsable-section-module__WXtqqW__header:focus-visible {
  outline: 2px solid var(--focus-ring-color);
  outline-offset: 2px;
}

.collapsable-section-module__WXtqqW__wrapper:not([data-expanded]) .collapsable-section-module__WXtqqW__header:hover {
  background-color: var(--ui-background-hover);
}

.collapsable-section-module__WXtqqW__wrapper[data-expanded] .collapsable-section-module__WXtqqW__header {
  background-color: var(--accent-9);
  color: var(--accent-1);
}

.collapsable-section-module__WXtqqW__wrapper[data-expanded] .collapsable-section-module__WXtqqW__header:hover {
  background-color: var(--accent-10);
}

.collapsable-section-module__WXtqqW__wrapper[data-expanded] .collapsable-section-module__WXtqqW__headerIcon {
  --rotate: 90deg;
  transform: rotate(var(--rotate));
}

.collapsable-section-module__WXtqqW__wrapper[data-expanded] .collapsable-section-module__WXtqqW__headerButtons {
  display: none;
}

.collapsable-section-module__WXtqqW__headerIcon {
  transform-origin: center;
}

.collapsable-section-module__WXtqqW__headerButtons {
  right: var(--space-4);
  height: 60px;
  position: absolute;
  top: 0;
}

.collapsable-section-module__WXtqqW__content {
  border: solid var(--ui-border);
  padding: var(--space-4);
  border-width: 0 1px 1px;
  overflow: hidden;
}

.description-module__6AfMSG__description {
  color: var(--text-subtle);
  margin-top: var(--size-1);
  display: flex;
}

.field-error-module__797uwG__fieldError {
  color: var(--accent-red-12);
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-1);
  margin-top: var(--size-1);
  display: flex;
}

.label-module__6rH4oG__label {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--size-1);
  display: flex;
}

[data-required] > .label-module__6rH4oG__label:after {
  content: "*";
  margin-left: .1em;
}

.input-module__auAaPW__input {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--field-background);
  border-radius: var(--radius-2);
  border: 1px solid var(--border-color);
  color: var(--text);
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  outline: none;
  width: 100%;
  padding: .5rem;
  line-height: normal;
  transition: border .3s ease-in-out;
}

.input-module__auAaPW__input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.input-module__auAaPW__input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.input-module__auAaPW__input::selection {
  background-color: var(--text-field-selection-color);
  color: var(--text);
}

.input-module__auAaPW__input[data-disabled] {
  background-color: var(--gray-a2);
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.input-module__auAaPW__input[data-hovered] {
  border-color: var(--gray-8);
}

.input-module__auAaPW__input[data-focused] {
  border-color: var(--gray-8);
  outline: 2px solid var(--focus-ring-color);
  outline-offset: -1px;
}

.no-data-module__LJi4RG__noData {
  height: calc(100% - 20px);
  padding: var(--space-5);
  text-align: center;
  z-index: 2;
  width: 100%;
  max-width: 680px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.avatar-module__ukXwdq__avatar {
  background-color: var(--ui-background);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.avatar-module__ukXwdq__sizeSm {
  width: 2rem;
  height: 2rem;
}

.avatar-module__ukXwdq__sizeMd {
  width: 3rem;
  height: 3rem;
}

.avatar-module__ukXwdq__sizeLg {
  width: 11rem;
  height: 11rem;
}

.avatar-module__ukXwdq__fitCover {
  object-fit: cover;
}

.avatar-module__ukXwdq__fitContain {
  object-fit: contain;
}

.avatar-module__ukXwdq__fallback {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  line-height: 1;
}

.avatar-module__ukXwdq__fallbackLg {
  font-size: var(--font-size-8);
}

.card-module__ieIPXa__card {
  background-color: var(--ui-background);
  border-radius: var(--radius-5);
  gap: var(--space-2);
  padding: var(--space-4);
  flex-direction: column;
  display: flex;
}

.card-module__ieIPXa__dark .card-module__ieIPXa__card {
  border: 1px solid var(--border-subtle);
}

.dialog-module__SFb9QW__modalOverlay {
  width: 100vw;
  height: var(--visual-viewport-height);
  z-index: 50;
  background-color: var(--black-a6);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.dialog-module__SFb9QW__modalOverlay[data-entering] {
  animation-name: dialog-module__SFb9QW__modal-fade;
  animation-duration: .15s;
}

.dialog-module__SFb9QW__modalOverlay[data-exiting] {
  animation-name: dialog-module__SFb9QW__modal-fade;
  animation-duration: .15s;
  animation-timing-function: ease-in;
  animation-direction: reverse;
}

.dialog-module__SFb9QW__modal {
  background-color: var(--gray-2);
  border-radius: var(--radius-6);
  box-shadow: var(--shadow-6);
  min-width: 30rem;
  max-width: calc(100% - 3rem);
}

.dialog-module__SFb9QW__modal[data-entering] {
  animation: .15s cubic-bezier(.4, 0, .2, 1) dialog-module__SFb9QW__modal-zoom;
}

.dialog-module__SFb9QW__dialog {
  outline: none;
  max-width: 100%;
  height: 100%;
  max-height: 80vh;
  overflow: auto;
}

.dialog-module__SFb9QW__dialogHeader {
  border-bottom: 1px solid var(--border-subtle);
  padding-inline: var(--space-4);
  padding-block: var(--space-3);
}

.dialog-module__SFb9QW__dialogContent {
  padding: var(--space-4);
}

.dialog-module__SFb9QW__dialogFooter {
  padding-inline: var(--space-4);
  padding-bottom: var(--space-4);
}

.dialog-module__SFb9QW__sizeSm {
  width: 500px;
}

.dialog-module__SFb9QW__sizeRg {
  width: 600px;
}

.dialog-module__SFb9QW__sizeMd {
  width: 880px;
}

.dialog-module__SFb9QW__sizeLg {
  width: 1040px;
}

.dialog-module__SFb9QW__sizeXl {
  width: 1280px;
}

@keyframes dialog-module__SFb9QW__modal-fade {
  from {
    -webkit-backdrop-filter: blur();
    backdrop-filter: blur();
    opacity: 0;
  }
}

@keyframes dialog-module__SFb9QW__modal-zoom {
  from {
    opacity: 0;
    transform: scale(.95);
  }
}

.separator-module__B2vtyW__separator {
  border-top: 1px solid var(--gray-a6);
  width: 100%;
  height: 0;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.list-box-module__xcvgoa__listBoxItem {
  cursor: default;
  scroll-margin: var(--space-2);
  font-size: var(--font-size-1);
  align-items: center;
  gap: var(--space-2);
  border-radius: var(--radius-2);
  padding: var(--space-2);
  text-align: left;
  display: flex;
  outline: none !important;
}

.list-box-module__xcvgoa__listBoxItem[data-focus-visible] {
  outline: none;
}

.list-box-module__xcvgoa__listBoxItem[data-selected] {
  font-weight: 600;
}

.list-box-module__xcvgoa__listBoxItem[data-hovered] {
  background-color: var(--ui-background-hover);
  color: var(--text-color);
}

.list-box-module__xcvgoa__listBoxItem[data-focused] {
  background-color: var(--gray-a4);
  color: var(--text-color);
}

.list-box-module__xcvgoa__listBoxItem[data-pressed] {
  background-color: var(--gray-a4);
  color: var(--text-color);
}

.list-box-module__xcvgoa__listBoxItem[data-disabled] {
  color: var(--text-color-disabled);
}

.hover-card-module__ptiF6W__hoverCard {
  box-shadow: var(--shadow-4);
  border-radius: var(--radius-2);
  background-color: var(--gray-2);
  forced-color-adjust: none;
  outline: none;
  min-width: 10rem;
  padding: 1rem;
  transform: translate3d(0, 0, 0);
}

.hover-card-module__ptiF6W__hoverCard[data-placement="top"] {
  --origin: translateY(4px);
  margin-bottom: 8px;
}

.hover-card-module__ptiF6W__hoverCard[data-placement="bottom"] {
  --origin: translateY(-4px);
  margin-top: 8px;
}

.hover-card-module__ptiF6W__hoverCard[data-placement="right"] {
  --origin: translateX(-4px);
  margin-left: 8px;
}

.hover-card-module__ptiF6W__hoverCard[data-placement="left"] {
  --origin: translateX(4px);
  margin-right: 8px;
}

.hover-card-module__ptiF6W__hoverCard[data-entering] {
  animation-name: hover-card-module__ptiF6W__slide;
  animation-duration: .2s;
}

.hover-card-module__ptiF6W__hoverCard[data-exiting] {
  animation-name: hover-card-module__ptiF6W__slide;
  animation-duration: .2s;
  animation-timing-function: ease-in;
  animation-direction: reverse;
}

@keyframes hover-card-module__ptiF6W__slide {
  from {
    transform: var(--origin);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.page-header-module__HRtaLq__pageHeader {
  border-bottom: 1px solid var(--border-subtle);
  gap: var(--space-5);
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: var(--space-4);
  display: flex;
}

.page-header-module__HRtaLq__pageHeaderTitle {
  font-size: var(--font-size-5);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  letter-spacing: .1125rem;
  text-transform: uppercase;
  margin: 0;
  line-height: normal;
}

.page-header-module__HRtaLq__pageHeaderLinks {
  gap: var(--space-5);
  display: inline-flex;
}

.form-layout-module__IpIrla__formLayout + .form-layout-module__IpIrla__formLayout {
  margin-top: var(--size-8);
}

@keyframes spinner-module__g_Sj5W__rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-module__g_Sj5W__dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124px;
  }
}

.spinner-module__g_Sj5W__wrapper {
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.spinner-module__g_Sj5W__loader {
  width: 25px;
  margin: 0 auto;
  position: relative;
}

.spinner-module__g_Sj5W__loader:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.spinner-module__g_Sj5W__circular {
  transform-origin: center;
  width: 100%;
  height: 100%;
  margin: auto;
  animation-name: spinner-module__g_Sj5W__rotate;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner-module__g_Sj5W__path {
  stroke-dasharray: 1 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke: var(--color-white);
  animation-name: spinner-module__g_Sj5W__dash;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-module__g_Sj5W__pathDark {
  stroke: var(--color-red);
}

.priority-card-module__9-zxEG__wrapper {
  z-index: 1;
  width: calc(100% + 30px);
  height: calc(100% - 20px);
  position: absolute;
  top: 0;
  left: -20px;
}

.priority-card-module__9-zxEG__tooltip {
  background-color: var(--gray-2);
  box-shadow: var(--shadow-4);
  border-radius: var(--radius-1);
  padding: 20px 10px;
}

.priority-card-module__9-zxEG__tooltipCell {
  padding: 0 20px;
}

.priority-card-module__9-zxEG__tooltipCell + .priority-card-module__9-zxEG__tooltipCell {
  margin-top: 25px;
}

.priority-card-module__9-zxEG__tooltipTitle {
  color: var(--gray-12);
  font-size: var(--font-size-1);
  opacity: .5;
  white-space: nowrap;
}

.priority-card-module__9-zxEG__tooltipValue {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}

.ranking-bar-chart-module__50r_0q__wrapper {
  width: calc(100% + 20px);
  height: 100%;
  position: absolute;
  top: 0;
  left: -20px;
}

.segmented-control-module__cBwNLa__list {
  background-color: var(--gray-a3);
  border-radius: var(--radius-4);
  padding: var(--size-1);
  align-items: center;
  display: inline-flex;
}

.segmented-control-module__cBwNLa__item {
  position: relative;
}

.segmented-control-module__cBwNLa__button {
  color: var(--text-color);
  align-items: center;
  gap: var(--size-2);
  height: 2rem;
  line-height: 1;
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-1);
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  padding: .5rem 1rem;
  display: inline-flex;
  position: relative;
}

.segmented-control-module__cBwNLa__label {
  z-index: 2;
  position: relative;
}

.segmented-control-module__cBwNLa__active {
  background-color: var(--gray-surface);
  content: "";
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-5);
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.survey-language-select-module__jEbOYa__wrapper {
  font-size: var(--font-size-2);
}

.survey-language-select-module__jEbOYa__button {
  white-space: nowrap;
  align-items: center;
  gap: 10px;
  display: flex;
}

.workspace-module__7fyviq__wrapper {
  height: 100vh;
  max-height: 100vh;
  display: flex;
}

@supports (-webkit-touch-callout: none) {
  .workspace-module__7fyviq__wrapper {
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
}

.workspace-module__7fyviq__header {
  width: 100%;
  min-height: 50px;
  margin-top: 20px;
  display: flex;
}

.workspace-module__7fyviq__main {
  flex: auto;
  max-width: 100%;
}

.workspace-module__7fyviq__content {
  height: calc(100% - 150px);
  padding: 20px 0;
  display: flex;
}

.workspace-module__7fyviq__column {
  background-color: var(--app-background);
  border: 1px solid var(--border-subtle);
  border-radius: var(--radius-2);
  color: var(--gray-12);
  max-height: 100%;
  overflow: hidden;
}

.workspace-module__7fyviq__columnLeft, .workspace-module__7fyviq__columnRight {
  flex: 25%;
  min-width: 200px;
  max-width: 420px;
}

.workspace-module__7fyviq__columnMiddle {
  flex: 50%;
  margin: 0 10px;
}

.workspace-module__7fyviq__columnMiddleOnly {
  margin: 0;
}

.workspace-module__7fyviq__columnHeader {
  border-bottom: 1px solid var(--border-subtle);
  font-size: 15px;
  font-weight: var(--font-weight-bold);
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.workspace-module__7fyviq__columnContent {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 50px);
  max-height: 100%;
  padding: 20px 15px 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.workspace-module__7fyviq__columnContentWithFooter {
  height: calc(100% - 100px);
}

.workspace-module__7fyviq__columnContentWithFooter .workspace-module__7fyviq__columnContentInner > :last-child:after {
  content: "";
  height: 20px;
  display: block;
}

.workspace-module__7fyviq__columnContentInner {
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
}

.workspace-module__7fyviq__columnFooter {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid var(--border-subtle);
  align-items: center;
  height: 50px;
  padding: 0 15px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.comments-module__r9bWdW__wrapper {
  max-width: 570px;
  margin: 0 auto;
}

.comments-module__r9bWdW__header {
  font-size: var(--font-size-1);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  display: flex;
}

.comments-module__r9bWdW__headerItem {
  flex: 0 0 33.3333%;
  align-items: center;
  height: 100%;
  display: flex;
}

.comments-module__r9bWdW__headerItemCenter {
  justify-content: center;
}

.comments-module__r9bWdW__headerItemLast {
  justify-content: flex-end;
  transform: translateX(10px);
}

.comments-module__r9bWdW__iconButton {
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 30px;
  transition: opacity .3s ease-out;
  display: inline-flex;
}

.comments-module__r9bWdW__iconButtonText {
  margin-left: 10px;
}

.comments-module__r9bWdW__iconButton[disabled] {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.comments-module__r9bWdW__comment {
  background-color: var(--ui-background);
  font-size: var(--font-size-1);
  padding: 10px 15px;
  line-height: 1.3;
}

.comments-module__r9bWdW__comment + .comments-module__r9bWdW__comment {
  margin-top: 10px;
}

.combo-box-module__RDUEsW__container {
  position: relative;
}

.combo-box-module__RDUEsW__input {
  padding-left: 2rem;
  padding-right: 2rem;
}

.combo-box-module__RDUEsW__icon {
  position: absolute;
  top: 50%;
  left: .5rem;
  transform: translateY(-50%);
}

.combo-box-module__RDUEsW__button {
  position: absolute;
  top: .25rem;
  right: .25rem;
}

.combo-box-module__RDUEsW__spinner {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%);
}

.tag-group-module__sQmZJG__tagGroup {
  flex-direction: column;
  display: flex;
}

.tag-group-module__sQmZJG__tagGroupDisabled {
  pointer-events: none;
}

.tag-group-module__sQmZJG__tagList {
  color: var(--gray-12);
  gap: var(--space-1);
  flex-wrap: wrap;
  display: flex;
}

.tag-group-module__sQmZJG__tag {
  background-color: var(--color-white);
  border-radius: var(--radius-4);
  border: 1px solid var(--ui-border);
  color: var(--gray-12);
  gap: var(--space-1);
  padding: var(--space-1) var(--space-2);
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-1);
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.tag-group-module__sQmZJG__tag button {
  height: var(--space-3);
  width: var(--space-3);
}

.tag-group-module__sQmZJG__tag[data-selected] {
  background-color: var(--accent-green-5);
  border-color: var(--accent-green-8);
}

.tag-group-module__sQmZJG__tag[data-hovered] {
  background-color: var(--ui-background-hover);
  border-color: var(--gray-8);
}

.multi-combo-box-module__R2IrnW__wrapper {
  border-radius: var(--radius-2);
  height: 2rem;
  position: relative;
}

.multi-combo-box-module__R2IrnW__placeholder {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-1);
}

.multi-combo-box-module__R2IrnW__trigger {
  width: 100%;
  height: 100%;
  padding: var(--space-2) var(--space-4);
  text-align: left;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.multi-combo-box-module__R2IrnW__tagGroup {
  max-width: calc(100% - var(--space-8));
  padding: calc(var(--space-1)  + 1px) var(--space-2) var(--space-1) var(--space-2);
  z-index: 1;
  position: relative;
}

.multi-combo-box-module__R2IrnW__tagGroupDisabled {
  pointer-events: none;
}

.multi-combo-box-module__R2IrnW__tagManySelected button {
  display: none;
}

.multi-combo-box-module__R2IrnW__listWrapper {
  min-width: 250px;
}

.multi-combo-box-module__R2IrnW__section + .multi-combo-box-module__R2IrnW__section {
  margin-top: var(--space-2);
}

.multi-combo-box-module__R2IrnW__header {
  background-color: var(--ui-background);
  padding: var(--space-2) var(--space-2);
}

.multi-combo-box-module__R2IrnW__itemIcon {
  left: var(--space-4);
  opacity: 0;
  position: absolute;
}

.multi-combo-box-module__R2IrnW__item[data-selected] .multi-combo-box-module__R2IrnW__itemIcon {
  opacity: 1;
}

.multi-combo-box-module__R2IrnW__item[data-selected] .multi-combo-box-module__R2IrnW__itemLabel {
  transform: translateX(var(--space-4));
}

.multi-combo-box-module__R2IrnW__noResults {
  padding: var(--space-2);
}

.radio-group-module__Ohl5ha__radioGroup {
  gap: var(--space-2);
  --disabled-background-color: var(--ui-background);
  --disabled-border-color: var(--gray-3);
  --disabled-color: var(--gray-7);
  flex-direction: column;
  display: flex;
}

.radio-group-module__Ohl5ha__radioGroup[data-orientation="horizontal"] .radio-group-module__Ohl5ha__radioGroupList {
  flex-direction: row;
  align-items: center;
}

.radio-group-module__Ohl5ha__radioGroup[data-orientation="horizontal"] .radio-group-module__Ohl5ha__radio {
  flex: 1;
}

.radio-group-module__Ohl5ha__radioGroupList {
  gap: var(--space-2);
  flex-direction: column;
  display: flex;
}

.radio-group-module__Ohl5ha__radio {
  background-color: var(--ui-background);
  border-radius: var(--radius-4);
  color: var(--text-color);
  align-items: center;
  gap: var(--space-4);
  padding: var(--space-2) var(--space-4);
  min-height: 4.5rem;
  font-size: var(--font-size-1);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  -webkit-user-select: none;
  user-select: none;
  line-height: normal;
  display: flex;
  position: relative;
}

.radio-group-module__Ohl5ha__radio svg {
  transition-property: fill;
  transition-timing-function: var(--ease);
  flex-shrink: 0;
  transition-duration: .15s;
}

.radio-group-module__Ohl5ha__radio[data-hovered] {
  background-color: var(--accent-green-4);
}

.radio-group-module__Ohl5ha__radio[data-pressed] {
  border-color: var(--border-color-pressed);
}

.radio-group-module__Ohl5ha__radio[data-selected] {
  background-color: var(--accent-green-track);
  color: var(--accent-green-contrast);
}

.radio-group-module__Ohl5ha__radio[data-disabled] {
  cursor: not-allowed;
  color: var(--disabled-color);
}

.radio-group-module__Ohl5ha__radio[data-focused] {
  outline: 2px solid var(--focus-ring-color);
  outline-offset: 2px;
}

.radio-group-module__Ohl5ha__radio[data-focus-visible] {
  outline: 2px solid var(--focus-ring-color);
  outline-offset: 2px;
}

.radio-group-module__Ohl5ha__radioTitle {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
  line-height: var(--line-height-3);
}

.radio-group-module__Ohl5ha__radioDescription {
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-1);
}

.bookings-table-module__iT4Voa__wrapper {
  position: relative;
}

.bookings-table-module__iT4Voa__chartWrapper {
  background-color: var(--gray-2);
  position: absolute;
  overflow: hidden;
}

.bookings-table-module__iT4Voa__table {
  font-size: var(--font-size-1);
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}

.bookings-table-module__iT4Voa__th, .bookings-table-module__iT4Voa__td {
  border: 1px solid var(--border-subtle);
  padding: 0;
}

.bookings-table-module__iT4Voa__cell {
  text-align: center;
}

.bookings-table-module__iT4Voa__button {
  font-weight: var(--font-weight-regular);
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: background .2s ease-out, color .2s ease-out;
  display: flex;
}

.bookings-table-module__iT4Voa__isDimmed {
  opacity: .3;
}

.bookings-table-module__iT4Voa__isHidden {
  opacity: 0;
}

.bookings-table-module__iT4Voa__buttonActive {
  background-color: var(--accent-9);
  color: var(--gray-1);
  font-weight: var(--font-weight-bold);
}

.bookings-table-module__iT4Voa__isTotalActive {
  font-weight: var(--font-weight-bold);
}

button.bookings-table-module__iT4Voa__button:not(.bookings-table-module__iT4Voa__buttonActive):hover {
  background-color: var(--ui-background);
}

.bookings-module__is3gsW__bookingRankingWrapper {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin-top: 20px;
  margin-left: 20px;
  position: relative;
}

.bookings-module__is3gsW__container {
  grid-gap: 10px;
  flex-grow: 1;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(10, 1fr);
  margin-bottom: 10px;
  display: grid;
}

.bookings-module__is3gsW__main {
  grid-area: 2 / 1 / 3 / 11;
  align-self: start;
}

.bookings-module__is3gsW__mainWithSidebar {
  grid-area: 2 / 1 / 3 / 8;
}

.bookings-module__is3gsW__footerSection {
  align-items: center;
  gap: 10px;
  display: flex;
}

.bookings-module__is3gsW__sidebar {
  grid-area: 2 / 8 / 3 / 11;
}

.bookings-module__is3gsW__rankingText {
  white-space: nowrap;
}

.header-module__N9H2bq__header {
  width: 100%;
  min-height: 50px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.header-module__N9H2bq__headerItem {
  flex: 0 0 33.3333%;
  justify-content: flex-start;
  display: flex;
}

.header-module__N9H2bq__headerItemCenter {
  justify-content: center;
}

.header-module__N9H2bq__headerItemRight {
  justify-content: flex-end;
}

.comment-module__okX-DG__wrapper {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.comment-module__okX-DG__header {
  border-bottom: 1px solid var(--border-subtle);
  justify-content: space-between;
  align-items: center;
  margin: 0 -20px;
  padding: 0 40px 20px;
  display: flex;
}

.comment-module__okX-DG__title {
  font-size: 15px;
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.comment-module__okX-DG__accountName {
  font-size: var(--font-size-1);
  opacity: .8;
  margin-top: 5px;
  position: absolute;
}

.comment-module__okX-DG__playDate {
  font-size: var(--font-size-1);
  opacity: .8;
  margin-left: auto;
  margin-right: 10px;
}

.comment-module__okX-DG__main {
  max-width: 980px;
  padding: 40px 20px;
}

.comment-module__okX-DG__question + .comment-module__okX-DG__question {
  margin-top: 40px;
}

.comment-module__okX-DG__questionTitle {
  font-size: 15px;
  font-weight: var(--font-weight-bold);
  margin-bottom: .5em;
}

.comment-module__okX-DG__questionAnswer {
  font-size: 15px;
  line-height: 1.4;
}

.comment-module__okX-DG__footer {
  border-top: 1px solid var(--border-subtle);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: auto -20px 0;
  padding: 20px 40px;
  display: flex;
}

.comment-module__okX-DG__footer > div {
  align-items: center;
  display: flex;
}

.comment-module__okX-DG__footerLink {
  font-size: var(--font-size-1);
  align-items: center;
  display: flex;
}

.comment-module__okX-DG__footerLink a {
  margin-left: 10px;
}

.comment-module__okX-DG__footerLink svg {
  max-height: 18px;
}

.comments-module__qyHfCa__headerItem {
  flex: 0 0 33.3333%;
  justify-content: flex-start;
  display: flex;
}

.comments-module__qyHfCa__headerItemCenter {
  justify-content: center;
}

.comments-module__qyHfCa__headerItemRight {
  justify-content: flex-end;
}

.comments-module__qyHfCa__resetButton {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-45%);
}

.comments-module__qyHfCa__wrapper {
  height: 100%;
  position: relative;
}

.comments-module__qyHfCa__listWrapper {
  width: 100%;
  height: calc(100% - 120px);
  position: absolute;
  top: 96px;
  overflow-y: auto;
}

.select-module__2zrgVa__button {
  width: 100%;
}

.select-module__2zrgVa__value {
  text-align: left;
  width: 100%;
}

.lists-module__Fs9HLW__headerItem {
  flex: 0 0 33.3333%;
  justify-content: flex-start;
  display: flex;
}

.lists-module__Fs9HLW__headerItemRight {
  justify-content: flex-end;
}

.lists-module__Fs9HLW__headerItemLeft, .lists-module__Fs9HLW__headerItemRight {
  flex: 25%;
  min-width: 200px;
  max-width: 380px;
}

.lists-module__Fs9HLW__headerItemMiddle {
  flex: 50%;
  margin: 0 10px;
}

.table-module__z_LnkG__tableWrapper {
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.table-module__z_LnkG__table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.table-module__z_LnkG__tableFooter .table-module__z_LnkG__tableCell {
  font-weight: var(--font-weight-bold);
}

.table-module__z_LnkG__tableRow:not(:last-child) {
  border-bottom: 1px solid var(--gray-a5);
}

:where(.table-module__z_LnkG__tableHead) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-2);
  font-weight: var(--font-weight-bold);
  text-align: left;
  height: 3rem;
  padding-inline: var(--space-4);
  line-height: normal;
  position: relative;
}

.table-module__z_LnkG__tableHeader .table-module__z_LnkG__tableHead {
  background-color: var(--ui-neutral-muted);
  color: var(--text-subtle);
}

.table-module__z_LnkG__tableHead:first-child, .table-module__z_LnkG__tableCell:first-child {
  border-top-left-radius: var(--space-2);
  border-bottom-left-radius: var(--space-2);
}

.table-module__z_LnkG__tableHead:last-child, .table-module__z_LnkG__tableCell:last-child {
  border-top-right-radius: var(--space-2);
  border-bottom-right-radius: var(--space-2);
}

.table-module__z_LnkG__tableHeader .table-module__z_LnkG__tableHead + .table-module__z_LnkG__tableHead:not(:empty):before {
  background-color: var(--gray-a5);
  content: "";
  height: calc(100% - var(--space-4));
  width: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.table-module__z_LnkG__tableCell {
  color: var(--text-color);
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-2);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  height: 3rem;
  padding-inline: var(--space-4);
  line-height: normal;
}

.section-module__s75ZzG__section {
  padding-block: var(--space-7);
}

.section-module__s75ZzG__sectionHeader {
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-5);
  display: flex;
}

@keyframes barchart-horizontal-module__zNR4hG__bar {
  from {
    width: 0;
  }

  to {
    width: var(--width);
  }
}

.barchart-horizontal-module__zNR4hG__item {
  border-top: 1px solid var(--border-subtle);
  gap: var(--space-2);
  padding-block: var(--space-4);
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.barchart-horizontal-module__zNR4hG__item:last-child {
  border-bottom: 1px solid var(--border-subtle);
}

.barchart-horizontal-module__zNR4hG__bars {
  gap: var(--space-2);
  flex-direction: column;
  width: 100%;
  padding-right: 7.5rem;
  display: flex;
  position: relative;
}

.barchart-horizontal-module__zNR4hG__bar {
  --width: calc(100% * var(--percent));
  background-color: var(--gray-6);
  border-top-right-radius: var(--radius-pill);
  border-bottom-right-radius: var(--radius-pill);
  width: 0;
  min-width: 1px;
  height: .625rem;
  animation-delay: calc(1s * var(--delay));
  animation-name: barchart-horizontal-module__zNR4hG__bar;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--back-inOut);
  position: relative;
}

.barchart-horizontal-module__zNR4hG__barResult.barchart-horizontal-module__zNR4hG__barPositive {
  background-color: var(--ui-positive);
}

.barchart-horizontal-module__zNR4hG__barResult.barchart-horizontal-module__zNR4hG__barNegative {
  background-color: var(--ui-negative);
}

.barchart-horizontal-module__zNR4hG__barTitle {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + .5rem), -50%);
}

@keyframes barchart-vertical-module__FOvtuW__fade {
  from {
    opacity: 0;
  }
}

@keyframes barchart-vertical-module__FOvtuW__bar {
  from {
    height: 0;
  }

  to {
    height: var(--height);
  }
}

.barchart-vertical-module__FOvtuW__barChartVertical {
  border-bottom: 1px solid var(--border-subtle);
  border-top: 1px solid var(--border-subtle);
}

.barchart-vertical-module__FOvtuW__item {
  gap: var(--space-2);
  border-left: 1px solid var(--border-subtle);
  flex-direction: column;
  flex: 1 1 0;
  min-width: 150px;
  max-width: 250px;
  display: flex;
}

.barchart-vertical-module__FOvtuW__item:last-child {
  border-right: 1px solid var(--border-subtle);
}

.barchart-vertical-module__FOvtuW__header {
  align-items: center;
  gap: var(--space-2);
  flex-direction: column;
  animation-name: barchart-vertical-module__FOvtuW__fade;
  animation-duration: .25s;
  display: flex;
}

.barchart-vertical-module__FOvtuW__itemTitle, .barchart-vertical-module__FOvtuW__itemValues {
  padding-block: var(--space-4);
  padding-inline: var(--space-4);
}

.barchart-vertical-module__FOvtuW__itemValues {
  border-bottom: 1px solid var(--border-subtle);
  border-top: 1px solid var(--border-subtle);
}

.barchart-vertical-module__FOvtuW__value {
  text-align: right;
}

.barchart-vertical-module__FOvtuW__benchmarkValue {
  color: var(--text-subtle);
  text-align: right;
}

.barchart-vertical-module__FOvtuW__bars {
  justify-content: center;
  gap: var(--space-5);
  height: 500px;
  padding-block: var(--space-4);
  display: flex;
  position: relative;
}

.barchart-vertical-module__FOvtuW__bars:after {
  background-color: var(--border-subtle);
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.barchart-vertical-module__FOvtuW__bar {
  --height: calc(50% * var(--percent)  - var(--space-4));
  background-color: var(--gray-6);
  transform-origin: 0 0;
  width: .625rem;
  height: 0;
  min-height: 1px;
  animation-delay: calc(1s * var(--delay));
  animation-name: barchart-vertical-module__FOvtuW__bar;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--back-inOut);
  position: relative;
  top: 50%;
}

.barchart-vertical-module__FOvtuW__barPositive {
  border-top-left-radius: var(--radius-pill);
  border-top-right-radius: var(--radius-pill);
  transform: translateY(-100%);
}

.barchart-vertical-module__FOvtuW__barNegative {
  border-bottom-left-radius: var(--radius-pill);
  border-bottom-right-radius: var(--radius-pill);
}

.barchart-vertical-module__FOvtuW__barResult.barchart-vertical-module__FOvtuW__barPositive {
  background-color: var(--ui-positive);
}

.barchart-vertical-module__FOvtuW__barResult.barchart-vertical-module__FOvtuW__barNegative {
  background-color: var(--ui-negative);
}

.donut-chart-module__MtZ79G__donut {
  flex-shrink: 0;
  position: relative;
}

.donut-chart-module__MtZ79G__donut:before {
  content: "";
  border-radius: var(--radius-circle);
  position: absolute;
  top: .75rem;
  bottom: .75rem;
  left: .75rem;
  right: .75rem;
  box-shadow: 0 8px 32px #11111a0d, 0 4px 16px #11111a0d;
}

.view-group-section-module__vxgHka__grid {
  grid-gap: var(--space-4);
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
  display: grid;
}

.view-group-section-module__vxgHka__additionalViewIcon {
  color: var(--text-subtle);
  border-radius: var(--radius-circle);
  display: inline-flex;
  position: relative;
}

.view-group-section-module__vxgHka__additionalViewIcon:hover {
  color: var(--text-color);
  scale: 1.1;
}

.tab-nav-module__7NmImG__tabNav {
  gap: var(--space-1);
  display: flex;
}

.tab-nav-module__7NmImG__tabNavLink {
  border-radius: var(--radius-3);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-semibold);
  align-items: center;
  gap: var(--space-3);
  letter-spacing: .025rem;
  height: 2rem;
  padding-inline: var(--space-3);
  text-transform: uppercase;
  text-wrap: nowrap;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-style: none;
  justify-content: center;
  line-height: normal;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  display: flex;
}

.tab-nav-module__7NmImG__tabNavLink:hover, .tab-nav-module__7NmImG__tabNavLink[data-hovered] {
  background-color: var(--ui-background-hover);
}

.tab-nav-module__7NmImG__tabNavLink[data-disabled] {
  opacity: .5;
}

.tab-nav-module__7NmImG__tabNavLink[data-focus-visible] {
  outline-style: solid;
}

.tab-nav-module__7NmImG__tabNavLink:active, .tab-nav-module__7NmImG__tabNavLink[data-pressed] {
  padding-top: .125rem;
}

.tab-nav-module__7NmImG__tabNavLink.tab-nav-module__7NmImG__isCurrent {
  background-color: var(--text-color);
  color: var(--gray-1);
}

.table-button-module__u0AAkG__tableButton {
  border-radius: var(--radius-2);
  font-size: .625rem;
  font-weight: var(--font-weight-semibold);
  align-items: center;
  gap: var(--space-1);
  height: 1.25rem;
  width: -webkit-fit-content;
  width: fit-content;
  padding-inline: var(--space-2);
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background-color: var(--ui-background);
  border-style: none;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  justify-content: space-between;
  line-height: normal;
  display: inline-flex;
}

.table-button-module__u0AAkG__tableButton[data-hovered] {
  background-color: var(--ui-background-hover);
  border-color: var(--ui-border-hover);
}

.table-button-module__u0AAkG__tableButton[data-active] {
  background-color: var(--gray-5);
}

.table-button-module__u0AAkG__tableButton[data-disabled] {
  color: var(--gray-8);
  cursor: not-allowed;
}

.switch-module__g0xXvq__switch {
  --green: #9ee1ab;
  --accent-green-dark: #8ed09c;
  --accent-green-darker: #7fc08c;
  --accent-green-light: #9ee1ab80;
  --background-color: var(--ui-background);
  --border-color: var(--gray-3);
  --knob-color: var(--text-subtle);
  --hovered-knob-color: var(--text-subtle);
  --pressed-background-color: var(--gray-5);
  --pressed-border-color: var(--gray-5);
  --pressed-knob-color: var(--text-subtle);
  --disabled-background-color: var(--ui-background);
  --disabled-border-color: var(--gray-3);
  --disabled-knob-color: var(--gray-7);
  --selected-background-color: var(--green);
  --selected-border-color: var(--green);
  --selected-knob-color: var(--gray-1);
  --selected-hovered-background-color: var(--accent-green-dark);
  --selected-hovered-border-color: var(--accent-green-dark);
  --selected-hovered-knob-color: var(--gray-1);
  --selected-pressed-background-color: var(--accent-green-darker);
  --selected-pressed-border-color: var(--accent-green-darker);
  --selected-pressed-knob-color: var(--gray-1);
  --selected-disabled-background-color: var(--accent-green-light);
  --selected-disabled-border-color: var(--accent-green-light);
  --selected-disabled-knob-color: var(--gray-1);
  --focus-visible-border-color: var(--gray-7);
  color: var(--text-subtle);
  align-items: center;
  gap: var(--size-2);
  forced-color-adjust: none;
  flex-shrink: 0;
  font-size: .875rem;
  display: inline-flex;
  position: relative;
}

@supports (color: lab(0% 0 0)) {
  .switch-module__g0xXvq__switch {
    --green: lab(83.9017% -29.8075 19.2441);
    --accent-green-dark: lab(78.0987% -29.8302 19.2821);
    --accent-green-darker: lab(72.2952% -29.8567 19.3263);
    --accent-green-light: lab(83.9017% -29.8075 19.2441 / .5);
  }
}

.switch-module__g0xXvq__switch[data-hovered] {
  --background-color: var(--ui-background-hover);
  --border-color: var(--ui-border-hover);
  --knob-color: var(--hovered-knob-color);
}

.switch-module__g0xXvq__switch[data-pressed] {
  --background-color: var(--pressed-background-color);
  --border-color: var(--pressed-border-color);
  --knob-color: var(--pressed-knob-color);
}

.switch-module__g0xXvq__switch[data-disabled] {
  --background-color: var(--disabled-background-color);
  --border-color: var(--disabled-border-color);
  --knob-color: var(--disabled-knob-color);
}

.switch-module__g0xXvq__switch[data-selected] {
  --background-color: var(--selected-background-color);
  --border-color: var(--selected-border-color);
  --knob-color: var(--selected-knob-color);
}

.switch-module__g0xXvq__switch[data-selected][data-hovered] {
  --background-color: var(--selected-hovered-background-color);
  --border-color: var(--selected-hovered-border-color);
  --knob-color: var(--selected-hovered-knob-color);
}

.switch-module__g0xXvq__switch[data-selected][data-pressed] {
  --background-color: var(--selected-pressed-background-color);
  --border-color: var(--selected-pressed-border-color);
  --knob-color: var(--selected-pressed-knob-color);
}

.switch-module__g0xXvq__switch[data-selected][data-disabled] {
  --background-color: var(--selected-disabled-background-color);
  --border-color: var(--selected-disabled-border-color);
  --knob-color: var(--selected-disabled-knob-color);
}

.switch-module__g0xXvq__switch[data-focus-visible] {
  --border-color: var(--focus-visible-border-color);
}

.switch-module__g0xXvq__switch[data-readonly] {
  cursor: default;
}

.switch-module__g0xXvq__switch[data-disabled] {
  cursor: not-allowed;
}

.switch-module__g0xXvq__indicator {
  background-color: var(--background-color);
  border-radius: var(--radius-pill);
  border: 2px solid #0000;
  width: 2rem;
  height: 1.25rem;
  transition: all .2s;
}

.switch-module__g0xXvq__indicator:before {
  content: "";
  background-color: var(--knob-color);
  border-radius: var(--radius-circle);
  width: 1rem;
  height: 1rem;
  transition: all .2s;
  display: block;
  position: absolute;
  top: calc(50% - .5rem);
  left: .125rem;
}

.switch-module__g0xXvq__switch[data-hovered] {
  color: var(--text-color);
}

.switch-module__g0xXvq__switch[data-selected] {
  color: var(--text-color);
}

.switch-module__g0xXvq__switch[data-selected] .switch-module__g0xXvq__indicator:before {
  transform: translateX(calc(100% - var(--size-1)));
}

.mao-notifications-module__lT2P0W__nameWrapper {
  align-items: center;
  display: flex;
}

.mao-notifications-module__lT2P0W__info {
  margin-left: 1em;
  line-height: 1.4;
}

.mao-notifications-module__lT2P0W__notifications {
  gap: var(--size-2);
  flex-wrap: wrap;
  display: flex;
}

.mao-notifications-module__lT2P0W__notification {
  border-radius: var(--radius-2);
  border: 1px solid var(--color-grey-blue);
  padding: var(--size-2);
  display: inline-flex;
}

.text-field-module__Ylfxnq__textField {
  width: 100%;
}

.billing-dialog-module__0x5Z8W__list {
  border: solid var(--border-subtle);
  padding: var(--space-2) 0;
  margin: var(--space-4) 0;
  border-width: 1px 0;
}

.billing-dialog-module__0x5Z8W__listItem {
  padding: var(--space-2) 0;
  justify-content: space-between;
  display: flex;
}

.clubs-module__2X6mXa__headerItem {
  flex: 0 0 33.3333%;
  justify-content: flex-start;
  display: flex;
}

.clubs-module__2X6mXa__headerItemRight {
  justify-content: flex-end;
}

.clubs-module__2X6mXa__headerItemLeft, .clubs-module__2X6mXa__headerItemRight {
  flex: 25%;
  min-width: 200px;
  max-width: 380px;
}

.clubs-module__2X6mXa__headerItemMiddle {
  flex: 50%;
  margin: 0 10px;
}

.editable-image-module__IQ3zaa__wrapper {
  position: relative;
}

.editable-image-module__IQ3zaa__dropzone {
  forced-color-adjust: none;
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  text-align: center;
  aspect-ratio: 1;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1rem;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.editable-image-module__IQ3zaa__dropzone[data-drop-target] {
  background-color: var(--accent-green-4);
  color: var(--gray-white);
}

.editable-image-module__IQ3zaa__deleteButton {
  background-color: var(--accent-9);
  color: var(--gray-1);
  font-size: var(--font-size-2);
  opacity: 0;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.editable-image-module__IQ3zaa__deleteButton[data-hovered] {
  opacity: 1;
}

.editable-image-module__IQ3zaa__fileTrigger {
  background-color: var(--ui-background);
  font-size: var(--font-size-2);
  text-align: center;
  z-index: 1;
  transition: color .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.editable-image-module__IQ3zaa__fileTrigger[data-hovered] {
  background-color: var(--ui-background-hover);
}

.editable-image-module__IQ3zaa__imageWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.user-avatar-module__nnehCq__wrapper {
  border-radius: var(--radius-circle);
  width: 175px;
  height: 175px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.user-profile-module__ygcfOa__center {
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.user-profile-module__ygcfOa__title {
  font-size: 30px;
  font-weight: var(--font-weight-extrabold);
  margin-top: 15px;
}

.user-profile-module__ygcfOa__button {
  align-self: flex-end;
  margin-top: 50px;
}

.account-cover-image-module__-fZukW__imageWrapper {
  width: 100%;
  height: 350px;
  position: relative;
}

.account-cover-image-module__-fZukW__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.account-cover-image-module__-fZukW__wrapper {
  background-color: var(--ui-background);
  width: 100%;
  height: 350px;
  position: relative;
  overflow: hidden;
}

.account-logo-module__wusMVG__wrapper {
  width: 175px;
  height: 175px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.account-profile-module__WFnaya__topWrapper {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}

.account-profile-module__WFnaya__accountLogo {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 25%;
  transform: translate(-50%, 50%);
}

.account-profile-module__WFnaya__center {
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.account-profile-module__WFnaya__title {
  font-size: 30px;
  font-weight: var(--font-weight-extrabold);
}

.account-profile-module__WFnaya__button {
  align-self: flex-end;
  margin-top: 50px;
}

.page-header-module__r1mxTq__wrapper {
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 0;
  display: flex;
}

.page-header-module__r1mxTq__title {
  color: var(--text);
  font-size: 42px;
  font-size: var(--font-size-8);
  font-weight: var(--font-weight-extrabold);
  text-transform: uppercase;
}

.page-header-module__r1mxTq__text {
  color: var(--text-subtle);
  white-space: pre-wrap;
}

.page-header-module__r1mxTq__body {
  margin-top: var(--space-3);
}

.error-module__G-rKUW__wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 40px;
  display: flex;
  position: relative;
}

@supports (-webkit-touch-callout: none) {
  .error-module__G-rKUW__wrapper {
    min-height: -webkit-fill-available;
  }
}

.invite-form-module__tC4v_a__form {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.invite-form-module__tC4v_a__content {
  width: 100%;
  padding: 0 20px 40px;
}

.invite-form-module__tC4v_a__legal {
  text-align: center;
  padding: 0 50px;
  font-size: 13px;
}

.invite-module__iGm33q__wrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 40px 0;
  display: flex;
  position: relative;
}

@supports (-webkit-touch-callout: none) {
  .invite-module__iGm33q__wrapper {
    min-height: -webkit-fill-available;
  }
}

.invite-module__iGm33q__content {
  width: 100%;
}

.invite-module__iGm33q__languageSelect {
  justify-content: center;
  display: flex;
}

.invite-module__iGm33q__footer {
  margin-top: 100px;
  display: flex;
}

.invite-module__iGm33q__footerItem + .invite-module__iGm33q__footerItem {
  margin-left: 50px;
}

.invite-module__iGm33q__legal {
  text-align: center;
  padding: 0 50px;
  font-size: 13px;
}

.sign-up-form-module__LYsbKW__form {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.sign-up-form-module__LYsbKW__content {
  width: 100%;
  padding: 0 20px 40px;
}

.sign-up-form-module__LYsbKW__legal {
  text-align: center;
  padding: 0 50px;
  font-size: 13px;
}

.global-navigation-module__X991nG__wrapper {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  background-color: var(--color-red);
  color: var(--color-white);
  z-index: 2147483004;
  overscroll-behavior-y: contain;
  -ms-scroll-chaining: none;
  will-change: transform;
  outline: none;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 515px;
  height: 100%;
  padding: 0 30px;
  transition: transform .55s cubic-bezier(.785, .135, .15, .86) .1s;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(110%);
}

.global-navigation-module__X991nG__wrapper:before {
  content: "";
  opacity: .1;
  background: url("/images/p1.svg") center / cover no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.global-navigation-module__X991nG__wrapperHidden {
  opacity: 0;
}

.global-navigation-module__X991nG__wrapperActive {
  opacity: 1;
  transition-delay: 0s;
  transform: translateX(0);
}

.global-navigation-module__X991nG__navHeader {
  z-index: 1;
  justify-content: space-between;
  padding: 40px 0 0;
  display: flex;
  position: relative;
}

.global-navigation-module__X991nG__navMain {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.global-navigation-module__X991nG__footer {
  z-index: 1;
  justify-content: space-between;
  margin-bottom: 40px;
  display: flex;
  position: relative;
}

.global-navigation-module__X991nG__link {
  font-size: 32px;
  font-weight: var(--font-weight-extralight);
  align-items: center;
  line-height: 1;
  display: inline-flex;
  position: relative;
}

.global-navigation-module__X991nG__link span {
  transition: transform .3s;
  position: relative;
}

.global-navigation-module__X991nG__link:before {
  background-color: var(--gray-2);
  content: "";
  transform-origin: 0;
  width: 30px;
  height: 2px;
  transition: transform .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: scaleX(0);
}

.global-navigation-module__X991nG__link:hover span, .global-navigation-module__X991nG__link:focus span, .global-navigation-module__X991nG__linkActive span {
  transform: translateX(40px);
}

.global-navigation-module__X991nG__link:hover:before, .global-navigation-module__X991nG__link:focus:before, .global-navigation-module__X991nG__linkActive:before {
  transform: scaleX(1);
}

.global-navigation-module__X991nG__section {
  --index: 0;
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transition-delay: calc(.3s + .15s * var(--index));
  transition-delay: calc(50ms * (var(--length)  - (var(--index)  + 1)));
  transform: translateX(50px);
}

.global-navigation-module__X991nG__wrapperActive.global-navigation-module__X991nG__wrapperData .global-navigation-module__X991nG__section {
  opacity: 1;
  transition-delay: calc(.4s + 50ms * var(--index));
  transform: translateX(0);
}

.global-navigation-module__X991nG__sectionTitle {
  opacity: .5;
  font-size: 15px;
}

.global-navigation-module__X991nG__list {
  margin-bottom: 40px;
}

.global-navigation-module__X991nG__item {
  margin-top: 10px;
}

.global-navigation-module__X991nG__signOut {
  max-width: 48px;
}

.global-navigation-module__X991nG__signOutText {
  opacity: 0;
  transition: opacity .25s ease, opacity .25s transform;
  white-space: nowrap;
  padding-right: 20px;
  transform: translateX(50%);
}

.global-navigation-module__X991nG__signOutIcon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.global-navigation-module__X991nG__signOut:hover, .global-navigation-module__X991nG__signOut:focus {
  max-width: 300px;
}

.global-navigation-module__X991nG__signOut:hover .global-navigation-module__X991nG__signOutText, .global-navigation-module__X991nG__signOut:focus .global-navigation-module__X991nG__signOutText {
  opacity: 1;
  transform: translateX(0);
}

.global-navigation-module__X991nG__overlay {
  z-index: 3;
  background-color: var(--gray-12);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease-in-out .1s;
  position: fixed;
  top: 0;
  left: 0;
}

.global-navigation-module__X991nG__overlayHidden {
  visibility: hidden;
}

.global-navigation-module__X991nG__overlayActive {
  pointer-events: all;
  opacity: .8;
}

.global-navigation-module__X991nG__footerButton {
  border-radius: var(--radius-pill);
  color: var(--color-white);
  height: 48px;
  font-size: 13px;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  background-color: #ef5b58;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  line-height: 1;
  transition: background .3s;
  display: flex;
  overflow: hidden;
}

.global-navigation-module__X991nG__footerButton:hover, .global-navigation-module__X991nG__footerButton:focus {
  background-color: #e34544;
}

.global-navigation-module__X991nG__footerProfile {
  padding-left: var(--space-1);
}

.global-navigation-module__X991nG__footerProfileIcon {
  background-color: var(--gray-2);
  border-radius: var(--radius-circle);
  color: var(--text);
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: flex;
  overflow: hidden;
}

.global-navigation-module__X991nG__footerProfileAvatar {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}

.tag-navigation-module__yYxhzq__toggleButton {
  color: var(--color-white);
  z-index: 1;
  border-right: 1px solid #ffffff1a;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 100%;
  display: flex;
  position: relative;
}

.tag-navigation-module__yYxhzq__selectedItem {
  border-radius: var(--radius-pill);
  color: var(--color-white);
  font-size: var(--font-size-1);
  border: 1px solid #ffffffb3;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  padding: .5rem .5rem .5rem 1rem;
  line-height: 1;
  display: flex;
}

.tag-navigation-module__yYxhzq__resetButton {
  color: #ffffffb3;
  border-radius: var(--radius-circle);
  border: 1px solid;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin-left: .5rem;
  display: flex;
}

.tag-navigation-module__yYxhzq__resetButton:hover {
  border-color: var(--color-white);
}

.tag-navigation-module__yYxhzq__popover {
  box-shadow: var(--shadow-4);
  border-radius: var(--radius-4);
  color: #000;
  font-size: var(--font-size-2);
  min-width: var(--trigger-width);
  overscroll-behavior: contain;
  background-color: #fff;
  border: 1px solid #dae2e7;
  outline: none;
}

.tag-navigation-module__yYxhzq__popover[data-placement="top"] {
  --origin: translateY(8px);
}

.tag-navigation-module__yYxhzq__popover[data-placement="top"]:has(.tag-navigation-module__yYxhzq__popoverArrow) {
  margin-bottom: 6px;
}

.tag-navigation-module__yYxhzq__popover[data-placement="bottom"] {
  --origin: translateY(-8px);
}

.tag-navigation-module__yYxhzq__popover[data-placement="bottom"]:has(.tag-navigation-module__yYxhzq__popoverArrow) {
  margin-top: 6px;
}

.tag-navigation-module__yYxhzq__popover[data-placement="bottom"] .tag-navigation-module__yYxhzq__popoverArrow svg {
  transform: rotate(180deg);
}

.tag-navigation-module__yYxhzq__popover[data-placement="right"] {
  --origin: translateX(-8px);
}

.tag-navigation-module__yYxhzq__popover[data-placement="right"]:has(.tag-navigation-module__yYxhzq__popoverArrow) {
  margin-left: 6px;
}

.tag-navigation-module__yYxhzq__popover[data-placement="right"] .tag-navigation-module__yYxhzq__popoverArrow svg {
  transform: rotate(90deg);
}

.tag-navigation-module__yYxhzq__popover[data-placement="left"] {
  --origin: translateX(8px);
}

.tag-navigation-module__yYxhzq__popover[data-placement="left"]:has(.tag-navigation-module__yYxhzq__popoverArrow) {
  margin-right: 6px;
}

.tag-navigation-module__yYxhzq__popover[data-placement="left"] .tag-navigation-module__yYxhzq__popoverArrow svg {
  transform: rotate(-90deg);
}

.tag-navigation-module__yYxhzq__popover[data-entering] {
  animation-name: tag-navigation-module__yYxhzq__popover-slide;
  animation-duration: .2s;
}

.tag-navigation-module__yYxhzq__popover[data-exiting] {
  animation-name: tag-navigation-module__yYxhzq__popover-slide;
  animation-duration: .2s;
  animation-timing-function: ease-in;
  animation-direction: reverse;
}

@keyframes tag-navigation-module__yYxhzq__popover-slide {
  from {
    transform: var(--origin);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tag-navigation-module__yYxhzq__searchWrapper {
  background-color: var(--app-background);
  padding: var(--space-2);
  z-index: 1;
  width: 100%;
  position: absolute;
}

.tag-navigation-module__yYxhzq__menu {
  max-height: inherit;
  padding: 2px;
  padding: var(--space-2);
  outline: none;
  min-width: 220px;
  font-size: .8125rem;
  position: relative;
  overflow: auto;
}

.tag-navigation-module__yYxhzq__menuItem {
  border-radius: var(--radius-3);
  cursor: pointer;
  padding-inline: var(--space-4);
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2rem;
  transition: background .3s;
  display: flex;
}

.tag-navigation-module__yYxhzq__menuItem[data-hovered] {
  background-color: var(--ui-background-hover);
}

.tag-navigation-module__yYxhzq__menuItem[data-selected] {
  font-weight: 600;
}

.tag-navigation-module__yYxhzq__menuItem[data-focused] {
  background-color: var(--gray-a4);
  color: var(--text-color);
}

.tag-navigation-module__yYxhzq__menuItem[data-pressed] {
  background-color: var(--gray-a4);
  color: var(--text-color);
}

.tag-navigation-module__yYxhzq__menuItemButton {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 2rem;
  padding: 0 .5rem;
  display: flex;
}

.tag-navigation-module__yYxhzq__menuName {
  align-items: center;
  min-height: 2rem;
  padding: 0 .5rem;
  display: flex;
}

.tag-navigation-module__yYxhzq__searchForm {
  justify-content: center;
  align-items: center;
  display: flex;
}

.account-selector-dialog-module__LDANIa__accountButton {
  text-align: left;
  font-size: 13px;
}

.account-selector-dialog-module__LDANIa__accountButton:hover, .account-selector-dialog-module__LDANIa__accountButton:focus, .account-selector-dialog-module__LDANIa__accountButtonActive {
  font-weight: var(--font-weight-bold);
}

.account-selector-dialog-module__LDANIa__section {
  background-color: var(--ui-background);
  border-radius: var(--radius-3);
  border: 1px solid #0000;
}

.account-selector-dialog-module__LDANIa__section[open] {
  border-color: var(--ui-border);
}

.account-selector-dialog-module__LDANIa__section[open] .account-selector-dialog-module__LDANIa__header {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.account-selector-dialog-module__LDANIa__section[open] .account-selector-dialog-module__LDANIa__header svg {
  transform: rotate(180deg);
}

.account-selector-dialog-module__LDANIa__header {
  background-color: var(--ui-background);
  border-radius: var(--radius-3);
  color: var(--gray-12);
  cursor: pointer;
  padding: var(--space-2) var(--space-4);
  z-index: 1;
  align-items: center;
  transition: background .3s ease-out;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.account-selector-dialog-module__LDANIa__header:hover {
  background-color: var(--ui-background-hover);
}

.account-selector-dialog-module__LDANIa__header:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-color: var(--focus-ring-color);
}

.account-selector-dialog-module__LDANIa__headerTitle {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-2);
  font-weight: var(--font-weight-bold);
}

.account-selector-dialog-module__LDANIa__content {
  border-bottom-left-radius: var(--radius-3);
  border-bottom-right-radius: var(--radius-3);
  padding: var(--space-2);
}

.account-selector-dialog-module__LDANIa__listBoxCollection {
  grid-template-rows: 58px 58px;
  grid-template-columns: none;
  grid-auto-columns: 250px;
  grid-auto-flow: column;
  gap: 8px;
  width: 100%;
  max-width: none;
  max-height: 200px;
  display: grid;
}

.account-selector-dialog-module__LDANIa__button {
  --button-color: var(--gray-12);
  background-color: var(--button-bg);
  border-radius: var(--radius-3);
  color: var(--button-color);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-medium);
  max-width: 100%;
  height: 2rem;
  padding-inline: var(--space-2);
  text-align: left;
  text-overflow: ellipsis;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  align-items: center;
  width: -webkit-min-content;
  width: min-content;
  line-height: normal;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  display: inline-flex;
  overflow: hidden;
}

.account-selector-dialog-module__LDANIa__button:hover {
  --button-bg: var(--gray-a4);
}

.account-selector-dialog-module__LDANIa__button:active {
  --button-bg: var(--gray-a5);
  padding-top: .125rem;
}

.account-selector-dialog-module__LDANIa__button:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--focus-ring-color);
}

.global-header-module__l6Vpja__header {
  width: 100%;
}

.global-header-module__l6Vpja__headerInner {
  background-color: var(--color-blue);
  color: var(--gray-12);
  justify-content: space-between;
  align-items: center;
  height: 80px;
  display: flex;
}

.global-header-module__l6Vpja__headerLeft, .global-header-module__l6Vpja__headerRight {
  align-items: center;
  height: 100%;
  display: flex;
}

.global-header-module__l6Vpja__headerLogo {
  border-right: 1px solid var(--border-subtle);
  z-index: 1;
  border-right: 1px solid #3a3a3a;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 100%;
  display: flex;
  position: relative;
}

.global-header-module__l6Vpja__headerLink {
  border-left: 1px solid var(--border-subtle);
  color: var(--gray-12);
  border-left: 1px solid #3a3a3a;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 100%;
  display: flex;
  position: relative;
}

.global-header-module__l6Vpja__linkExport {
  border-right: 1px solid var(--border-subtle);
  border-right: 1px solid #3a3a3a;
}

.global-header-module__l6Vpja__headerLink:hover, .global-header-module__l6Vpja__headerLinkActive {
  color: var(--color-red);
}

.global-header-module__l6Vpja__headerLink:focus-visible {
  color: var(--color-red);
}

.global-header-module__l6Vpja__accountWrapper {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.global-header-module__l6Vpja__accountName {
  color: var(--gray-12);
  align-items: baseline;
  margin-right: 20px;
  font-size: 13px;
  line-height: 1;
  display: flex;
}

.global-header-module__l6Vpja__indirectAccessAccountName {
  opacity: .5;
  margin-top: 10px;
}

.global-header-module__l6Vpja__accountNameText {
  margin-right: 5px;
}

.global-header-module__l6Vpja__accounts {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.global-header-module__l6Vpja__accountName {
  align-items: center;
  gap: var(--size-1);
  margin-right: 20px;
  font-size: 13px;
  line-height: 1;
  display: flex;
}

.global-header-module__l6Vpja__accountNameIndirect {
  opacity: .5;
  margin-top: 10px;
  transition: opacity .2s ease-out;
}

.global-header-module__l6Vpja__accountNameIndirect:hover, .global-header-module__l6Vpja__accountNameIndirect:focus {
  opacity: 1;
}

.global-header-module__l6Vpja__navToggle {
  border-left: 1px solid var(--border-subtle);
  color: var(--gray-12);
  border-left: 1px solid #3a3a3a;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 100%;
  display: flex;
  position: relative;
}

.global-header-module__l6Vpja__navToggle:focus-visible {
  outline-offset: -2px;
}

.global-header-module__l6Vpja__beta {
  background-color: var(--color-white);
  border-radius: var(--radius-2);
  color: var(--color-blue);
  letter-spacing: var(--letter-spacing-1);
  text-transform: uppercase;
  padding: 1px 6px;
  font-size: .625rem;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
}

.global-header-module__l6Vpja__headerLink:focus-visible .global-header-module__l6Vpja__beta {
  background-color: var(--color-red);
  color: var(--color-white);
}

:is(.global-header-module__l6Vpja__headerLink:hover, .global-header-module__l6Vpja__headerLinkActive) .global-header-module__l6Vpja__beta {
  background-color: var(--color-red);
  color: var(--color-white);
}

.global-header-module__l6Vpja__accountsName {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-1);
  padding-inline: var(--space-4);
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  align-items: center;
  display: inline-flex;
}

.global-loading-indicator-module__SJclLW__wrapper {
  --delay: .3s;
  --ease: ease-in;
  --opacity: 0;
  --y: 100px;
  background-color: var(--color-red);
  border-radius: var(--radius-circle);
  width: 42px;
  height: 42px;
  transform: translateY(var(--y));
  transition: background .3s var(--ease), opacity .3s var(--ease), transform .3s var(--ease);
  transition-delay: var(--delay);
  opacity: var(--opacity);
  z-index: 999999;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 19px;
  left: 19px;
}

.global-loading-indicator-module__SJclLW__isActive {
  --delay: 0s;
  --ease: ease-out;
  --opacity: 1;
  --y: 0;
}

.integration-settings-dialog-module__o9adGa__logo {
  background-color: var(--ui-background);
  border: 1px solid var(--border-subtle);
  border-radius: var(--radius-2);
  flex-shrink: 0;
  width: 3.5rem;
  height: 3.5rem;
  overflow: hidden;
}

.integration-settings-dialog-module__o9adGa__spinnerWrapper {
  transform: translateY(0);
}

.integration-settings-dialog-module__o9adGa__section {
  border-top: 1px solid var(--border-subtle);
  margin: 1.5rem 0;
  padding-top: 1.5rem;
}

.integration-settings-dialog-module__o9adGa__section:last-child {
  border-bottom: 1px solid solid var(--border-subtle);
  margin-bottom: 0;
  padding-bottom: 1.5rem;
}

.integration-wizard-dialog-module__0RtUGG__logoWrapper {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.integration-wizard-dialog-module__0RtUGG__logo {
  background-color: var(--ui-background);
  border: 1px solid var(--border-subtle);
  border-radius: var(--radius-2);
  flex-shrink: 0;
  width: 3.5rem;
  height: 3.5rem;
  overflow: hidden;
}

.integration-wizard-dialog-module__0RtUGG__spinnerWrapper {
  transform: translateY(0);
}

.integration-wizard-dialog-module__0RtUGG__content {
  text-align: center;
  margin-top: -2rem;
}

.integration-wizard-dialog-module__0RtUGG__section {
  border-top: 1px solid solid var(--border-subtle);
  margin: 1.5rem 0;
  padding-top: 1.5rem;
}

.integration-wizard-dialog-module__0RtUGG__section:last-child {
  border-bottom: 1px solid solid var(--border-subtle);
  margin-bottom: 0;
  padding-bottom: 1.5rem;
}

.integrations-provider-module__xEGT8a__container {
  grid-gap: 1rem;
  flex-grow: 1;
  grid-template-rows: 100%;
  grid-template-columns: repeat(10, 1fr);
  padding-bottom: 1rem;
  display: grid;
}

.integrations-provider-module__xEGT8a__main {
  background-color: var(--app-background);
  grid-column: 1 / span 7;
  align-self: start;
}

.integrations-provider-module__xEGT8a__sidebar {
  background-color: var(--app-background);
  text-align: center;
  flex-direction: column;
  grid-column: 8 / span 3;
  align-self: start;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  display: flex;
}

.integrations-provider-module__xEGT8a__buttonWrapper {
  gap: 1rem;
  display: flex;
}

.integrations-provider-module__xEGT8a__divider {
  flex-direction: column;
  display: flex;
}

.integrations-provider-module__xEGT8a__divider > :not(:first-child) {
  border-top: 1px solid var(--border-subtle);
  margin-top: 2rem;
  padding-top: 2rem;
}

.integrations-module__znA_WW__noIntegrations {
  text-align: center;
  white-space: pre-wrap;
  flex-direction: column;
  gap: 1rem;
  max-width: 660px;
  margin: 0 auto;
  display: flex;
}

.tee-times-dialog-module__NMJ5CG__table {
  border-collapse: collapse;
  width: 100%;
}

.tee-times-dialog-module__NMJ5CG__tableHead th {
  font-size: var(--font-size-1);
  text-align: left;
  padding: .5rem 1rem;
}

.tee-times-dialog-module__NMJ5CG__tableHead th:last-child {
  text-align: right;
}

.tee-times-dialog-module__NMJ5CG__tableRow {
  border: 1px solid var(--border-subtle);
  border-width: 1px 0;
  transition: opacity .2s ease-out;
}

.tee-times-dialog-module__NMJ5CG__tableRow:last-child {
  border-bottom-width: 0;
}

.tee-times-dialog-module__NMJ5CG__tableRowDisabled td:first-child {
  opacity: .3;
}

.tee-times-dialog-module__NMJ5CG__tableBody td {
  padding: .5rem 1rem;
  font-size: 13px;
}

.bi-settings-module__Dr3j8W__wrapper {
  justify-content: space-between;
  display: flex;
}

.bi-settings-module__Dr3j8W__wrapper > * {
  width: 540px;
}

.bi-settings-module__Dr3j8W__teeTimeItem {
  border-bottom: 1px solid var(--border-subtle);
  color: var(--gray-12);
  justify-content: space-between;
  align-items: center;
  padding: .75rem 0;
  font-size: 1rem;
  display: flex;
}

.bi-settings-module__Dr3j8W__teeTimeItemDisabled div {
  opacity: .3;
}

.bi-settings-module__Dr3j8W__buttonWrapper {
  justify-content: flex-end;
  margin-top: 40px;
  display: flex;
}

.automated-data-collection-module__3lrT9a__container > * {
  flex: 0 0 400px;
}

.automated-data-collection-module__3lrT9a__title {
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 10px;
}

.custom-benchmarks-module__Jvi6-a__account {
  border-radius: var(--radius-2);
  border: 1px solid var(--color-grey-blue);
  padding: .5em;
  display: inline-flex;
}

.date-time-picker-module__zbcAfG__label {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-1);
  margin-bottom: var(--size-1);
  display: inline-flex;
}

.date-time-picker-module__zbcAfG__label:has( + [required]):after {
  content: "*";
  margin-left: .1em;
}

.date-time-picker-module__zbcAfG__wrapper {
  background-color: var(--gray-2);
  width: 100%;
  position: relative;
}

.date-time-picker-module__zbcAfG__toggle {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-white);
  border: 1px solid var(--border-subtle);
  border-radius: var(--radius-2);
  color: var(--gray-12);
  text-align: left;
  outline: none;
  width: 100%;
  height: 2rem;
  margin: 0;
  padding-left: 1rem;
  padding-right: 2.25rem;
  font-size: 13px;
  line-height: normal;
  transition: border .3s ease-in-out;
  display: block;
}

.date-time-picker-module__zbcAfG__toggle:hover, .date-time-picker-module__zbcAfG__toggle:focus {
  border-color: var(--ui-border-hover);
}

.date-time-picker-module__zbcAfG__toggle:focus-visible {
  border-color: var(--focus-ring-color);
}

.date-time-picker-module__zbcAfG__toggle[disabled], .date-time-picker-module__zbcAfG__toggle[disabled] + * {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.date-time-picker-module__zbcAfG__toggleIcon {
  color: var(--color-grey-blue);
  pointer-events: none;
  position: absolute;
  top: 55%;
  right: 1.25rem;
  transform: translate(0, -50%);
}

.date-time-picker-module__zbcAfG__item {
  border-radius: var(--radius-2);
  display: flex;
  position: relative;
}

.date-time-picker-module__zbcAfG__item > * {
  text-align: left;
  white-space: nowrap;
  width: 100%;
  min-width: 150px;
  font-size: var(--font-size-1);
  gap: var(--space-2);
  border-radius: var(--radius-2);
  padding: var(--space-2);
  display: block;
  position: relative;
  outline: none !important;
}

.date-time-picker-module__zbcAfG__item:hover {
  background-color: var(--ui-background-hover);
  color: var(--text-color);
}

.date-time-picker-module__zbcAfG__help {
  color: var(--gray-12);
  margin-top: .5rem;
  font-size: 12px;
  line-height: 1.3;
  display: block;
}

.step-period-module__KaHnpW__datePickerItem {
  flex-direction: column;
  flex: 0 0 calc(50% - 50px);
  display: flex;
  position: relative;
}

.step-reminders-module__h8M8CW__help {
  color: var(--gray-12);
  font-size: var(--font-size-1);
  margin-bottom: 3rem;
  line-height: 1.3;
  display: block;
}

.step-reminders-module__h8M8CW__label {
  font-size: var(--font-size-1);
  opacity: .5;
  margin-bottom: 5px;
}

.step-reminders-module__h8M8CW__addButton {
  text-align: center;
  font-size: 20px;
}

.draw-a-winner-dialog-module__IQMWbG__icon {
  position: absolute;
  bottom: .25rem;
  right: .25rem;
}

.draw-a-winner-module__ohaq-a__contained {
  max-width: 780px;
  margin: 0 auto;
  display: flex;
}

.draw-a-winner-module__ohaq-a__title {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  text-align: center;
  margin-bottom: 1rem;
}

.draw-a-winner-module__ohaq-a__footer {
  text-align: center;
  line-height: 1.3;
}

.golf-genius-module__6boqqW__left {
  padding-right: 50px;
}

.golf-genius-module__6boqqW__right {
  flex-direction: column;
  padding-left: 50px;
  display: flex;
}

.golf-genius-module__6boqqW__title {
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 10px;
}

.golf-genius-module__6boqqW__button {
  align-self: flex-end;
  margin-top: 50px;
}

.integration-log-module__FBEKoW__avatar {
  margin-left: -5px;
}

.integration-log-module__FBEKoW__info {
  line-height: 1.4;
}

.integration-log-module__FBEKoW__notification {
  border-radius: var(--radius-2);
  border: 1px solid var(--color-grey-blue);
  white-space: pre-wrap;
  gap: 1rem;
  padding: .5em;
  line-height: 1.4;
  display: inline-flex;
}

.manage-automated-data-collection-module___RZcfG__container {
  grid-gap: 1rem;
  flex-grow: 1;
  grid-template-rows: 100%;
  grid-template-columns: repeat(10, 1fr);
  padding-bottom: 1rem;
  display: grid;
}

.manage-automated-data-collection-module___RZcfG__main {
  background-color: var(--app-background);
  grid-column: 1 / span 7;
}

.manage-automated-data-collection-module___RZcfG__mainFull {
  background-color: var(--app-background);
  grid-column: 1 / -1;
}

.manage-automated-data-collection-module___RZcfG__sidebar {
  background-color: var(--app-background);
  text-align: center;
  flex-direction: column;
  grid-column: 8 / span 3;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  display: flex;
}

.manage-automated-data-collection-module___RZcfG__sectionContent {
  justify-content: space-between;
  display: flex;
}

.manage-automated-data-collection-module___RZcfG__sectionContent + .manage-automated-data-collection-module___RZcfG__sectionContent {
  margin-top: 2.5rem;
}

.manage-automated-data-collection-module___RZcfG__sectionContent > * {
  width: 540px;
}

.manage-automated-data-collection-module___RZcfG__listTitle {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
  line-height: var(--line-height-3);
  font-weight: var(--font-weight-bold);
  margin-bottom: 10px;
}

.manage-automated-data-collection-module___RZcfG__listTitle:not(:first-child) {
  margin-top: 2.5rem;
}

.manage-automated-data-collection-module___RZcfG__listItem {
  border-bottom: 1px solid var(--border-subtle);
  padding-block: var(--space-3);
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-2);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.manage-automated-data-collection-module___RZcfG__list dd {
  margin-left: 0;
}

.manage-automated-data-collection-module___RZcfG__buttonWrapper {
  justify-content: flex-end;
  display: flex;
}

.manage-automated-data-collection-module___RZcfG__divider {
  flex-direction: column;
  display: flex;
}

.manage-automated-data-collection-module___RZcfG__divider > :not(:first-child) {
  border-top: 1px solid var(--border-subtle);
  margin-top: 2rem;
  padding-top: 2rem;
}

.notifications-module__G3cV1a__notifications {
  flex-wrap: wrap;
  gap: .5em;
  display: flex;
}

.notifications-module__G3cV1a__notification {
  border-radius: var(--radius-2);
  border: 1px solid var(--color-grey-blue);
  padding: .5em;
  display: inline-flex;
}

.notifications-module__G3cV1a__wrapper {
  align-items: center;
  display: flex;
}

.notifications-module__G3cV1a__info {
  margin-left: 1em;
  line-height: 1.4;
}

.basic-survey-info-module__Evn61W__container {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 100px auto 0;
  display: flex;
}

.basic-survey-info-module__Evn61W__sectionStart {
  flex-direction: column;
  align-items: center;
  width: 45%;
  display: flex;
}

.basic-survey-info-module__Evn61W__sectionEnd {
  width: 45%;
}

.basic-survey-info-module__Evn61W__languagesWrapper {
  border-bottom: 1px solid var(--border-subtle);
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
}

.basic-survey-info-module__Evn61W__languages {
  margin-left: 10px;
}

.basic-survey-info-module__Evn61W__warning {
  color: var(--color-red);
  font-size: 11px;
}

.basic-survey-info-module__Evn61W__accountLogo {
  position: absolute;
  bottom: 0;
  left: 25%;
  transform: translate(-50%, 50%);
}

.basic-survey-info-module__Evn61W__customSurveyTitle {
  font-size: 30px;
  font-weight: var(--font-weight-bold);
  text-align: center;
  margin-top: 20px;
}

.basic-survey-info-module__Evn61W__button {
  align-self: flex-end;
  margin-top: 50px;
}

.text-area-module__NZbkGq__textArea {
  field-sizing: content;
  min-height: calc(1.5rem * var(--rows, 4));
  min-height: calc(1lh * var(--rows, 4));
  resize: none;
  width: 100%;
  line-height: 1.5;
}

.theme-toggle-module__k33dwG__themeToggle {
  --duration: .5s;
}

.theme-toggle-module__k33dwG__themeToggleMask {
  transition-property: transform, d;
  transition-duration: calc(var(--duration) * .6);
  transition-timing-function: cubic-bezier(0, 0, .5, 1);
}

.theme-toggle-module__k33dwG__themeToggleCircle, .theme-toggle-module__k33dwG__themeTogglePath {
  transform-origin: center;
  transition: transform calc(var(--duration) * .65) cubic-bezier(0, 0, 0, 1.25) calc(var(--duration) * .35);
}

.theme-toggle-module__k33dwG__themeToggleDark .theme-toggle-module__k33dwG__themeToggleMask {
  d: path("M-9 3h25a1 1 0 0017 13v30H0Z");
  transition-delay: calc(var(--duration) * .4);
  transition-timing-function: cubic-bezier(0, 0, 0, 1.25);
}

.theme-toggle-module__k33dwG__themeToggleDark .theme-toggle-module__k33dwG__themeToggleCircle {
  transition-delay: 0s;
  transform: scale(1.4);
}

.theme-toggle-module__k33dwG__themeToggleDark .theme-toggle-module__k33dwG__themeTogglePath {
  transition-delay: 0s;
  transform: scale(.75);
}

.generic-input-module__M0r5EW__meta {
  color: var(--text-subtle);
  font-size: var(--font-size-2);
  text-align: right;
}

